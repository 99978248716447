/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, YouTube, SeparateLine, SeparateLineWrap, Button, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"media"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-xhq0xp --style3 --full pb--60" name={"g69tfgs12so"} style={{"paddingTop":35}} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/67ecc59a52294024bf8641b084c2858d_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--60 pt--60" name={"0lcreokqrfvm"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold;\">Doporučujeme: <br>Mgr. Štěpán Jílka v DVTV</span>"}>
              </Title>

              <Text className="text-box text-box--justify fs--13" content={"<span style=\"color: var(--black); font-weight: bold;\">„Pět let v úřadu ombudsmana mi přišlo, že stojíme ve slepé uličce. Všichni víme, že kvůli problematickému zákonu nedokážeme naplňovat potřeby a potenciál dětí v dětských domovech, ale nic se neděje. Nedostatek personálu nahrazujeme opatřeními zasahujícími do jejich práv a soukromí,“ popisuje právník úřadu ombudsmana Štěpán Jílka, který se nespokojil jen s inspekcemi v dětských zařízeních a nechal se v jednom z nich zaměstnat jako vychovatel. „V běžném životě řešíte krizové situace s dítětem jednou za čas, tady několikrát denně. Ano, děti jsou hlasitější a impulzivnější, ale má to příčinu. Ustály příběhy, které podle mě ani nejdou přežít,“ dodává.\n</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"UdjuFDpfNkE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"917lgammhuo"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"gkrgavca5x"} style={{"paddingTop":16,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 btn-box--filling2" url={"https://pardubice.rozhlas.cz/hostitelska-pece-nabizi-lepsi-zazemi-pro-deti-z-detskych-domovu-jak-se-stat-9121654?fbclid=IwAR1BqqgA1Gb0KkyGduo2UBDos5A6gJwzGBfTcs2bogXjCQp5Iq2wySgUphc"} href={"https://pardubice.rozhlas.cz/hostitelska-pece-nabizi-lepsi-zazemi-pro-deti-z-detskych-domovu-jak-se-stat-9121654?fbclid=IwAR1BqqgA1Gb0KkyGduo2UBDos5A6gJwzGBfTcs2bogXjCQp5Iq2wySgUphc"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"font-weight: bold; color: var(--black);\">Český rozhlas (2023)</span>"}>
              </Button>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 btn-box--filling2" url={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/"} href={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"font-weight: 700; color: var(--black);\">Studio 6 - ČT (2023)</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"g8gxwwrknmq"}>
          
          <ColumnWrap className="column__flex --left el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=860x_.jpg"} url={"https://www.youtube.com/watch?fbclid=IwAR1QsOrPvmjbwc6lsFi6bcG4pEUnbqbyk_LdR4PD4-H_JjuccS1w0o55lI0&v=-lZfk1uq1J4%E2%96%AA%EF%B8%8F&feature=youtu.be"} use={"url"} href={"https://www.youtube.com/watch?fbclid=IwAR1QsOrPvmjbwc6lsFi6bcG4pEUnbqbyk_LdR4PD4-H_JjuccS1w0o55lI0&v=-lZfk1uq1J4%E2%96%AA%EF%B8%8F&feature=youtu.be"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/41f0e1d9cf7449479aad626a75b7b3ca_s=2000x_.jpg 2000w"} target={"_blank"} lightbox={false} position={null}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--style7 subtitle-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\"><a href=\"https://www.hatefree.cz/clanky/i-pres-dramaticke-startovaci-podminky-to-nevzdal-sam-se-dnes-snazi-zlepsit-zivot-deti-v-ustavni-peci?fbclid=IwAR1EcQ1AJ6dkV0br7vSNj0e63Rp0PBfDV93Z74Rl_Wu-WhzhBXipZKIsNTI\" target=\"_blank\" style=\"\">HateFree.cz</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=860x_.JPG"} url={"https://www.youtube.com/watch?v=lVCRjWCsPrY&t=54s"} use={"url"} href={"https://www.youtube.com/watch?v=lVCRjWCsPrY&t=54s"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=350x_.JPG 350w, https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=660x_.JPG 660w, https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=860x_.JPG 860w, https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/17142/2b2339f324f049638eb349f23cf935e1_s=2000x_.JPG 2000w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\"><a href=\"https://pardubicky.denik.cz/ctenar-reporter/ladislav-vyrustal-v-detskem-domove-bojoval-se-skepsi-a-nedostatecnou-podporou-20.html?fbclid=IwAR2eJ0bDsp-EA-SMsoNy4uUwFr2D18A3LncREFgW0xxKC4YsVgL5CyLM-78\" target=\"_blank\">Pardubický deník</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d10dfcdeaf1d4eb393eb2be4ed1136de_s=860x_.jpg"} url={"https://pardubice.rozhlas.cz/na-detske-domovy-plivou-ti-kteri-tam-nikdy-nebyli-rika-reditel-neziskovky-deti-v-8605558?fbclid=IwAR2zdBq7vi7-8C12T0TbyFQUldH0_Q6UUIgqi45bQWlbXBXukwcyhNNjsYM#player=on"} use={"url"} href={"https://pardubice.rozhlas.cz/na-detske-domovy-plivou-ti-kteri-tam-nikdy-nebyli-rika-reditel-neziskovky-deti-v-8605558?fbclid=IwAR2zdBq7vi7-8C12T0TbyFQUldH0_Q6UUIgqi45bQWlbXBXukwcyhNNjsYM#player=on"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/d10dfcdeaf1d4eb393eb2be4ed1136de_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/d10dfcdeaf1d4eb393eb2be4ed1136de_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/d10dfcdeaf1d4eb393eb2be4ed1136de_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/d10dfcdeaf1d4eb393eb2be4ed1136de_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\"><a href=\"https://radiozurnal.rozhlas.cz/organizace-deti-v-akci-podporuje-deti-z-detskych-domovu-v-zajmovych-cinnostech-8479299\" target=\"_blank\">Český rozhlas - R</a>adiožurnál</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/2f47a53366184b9a85fcf0c8b15f07c7_e=220x572x822x519_s=660x_.jpg"} url={"https://soundcloud.com/ct24/uk-jaka-je-situace-v-detskych-domovech"} use={"url"} href={"https://soundcloud.com/ct24/uk-jaka-je-situace-v-detskych-domovech"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/2f47a53366184b9a85fcf0c8b15f07c7_e=220x572x822x519_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/2f47a53366184b9a85fcf0c8b15f07c7_e=220x572x822x519_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\"><a href=\"https://www.zamecek.net/cs/clanky/rozhovory/redakce-1/redakce/ladislav-samek-drive-dd-chrudim-uspesny-dospelak-z-detskeho/detail\" target=\"_blank\">Zámeček</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/32de9c058ae34690a44124f7cf9809f4_e=390x32x1339x753_s=660x_.jpg"} url={"https://www.youtube.com/watch?fbclid=IwAR1QsOrPvmjbwc6lsFi6bcG4pEUnbqbyk_LdR4PD4-H_JjuccS1w0o55lI0&v=-lZfk1uq1J4%E2%96%AA%EF%B8%8F&feature=youtu.be"} use={"url"} href={"https://www.youtube.com/watch?fbclid=IwAR1QsOrPvmjbwc6lsFi6bcG4pEUnbqbyk_LdR4PD4-H_JjuccS1w0o55lI0&v=-lZfk1uq1J4%E2%96%AA%EF%B8%8F&feature=youtu.be"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/32de9c058ae34690a44124f7cf9809f4_e=390x32x1339x753_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/32de9c058ae34690a44124f7cf9809f4_e=390x32x1339x753_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/32de9c058ae34690a44124f7cf9809f4_e=390x32x1339x753_s=860x_.jpg 860w"} target={"_blank"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Host Lucie Výborné</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/9b0b1633ad884757971edbd5d04969a5_e=0x0x960x540_bri=115_s=660x_.jpg"} url={"https://www.youtube.com/watch?v=lVCRjWCsPrY&t=54s"} use={"url"} href={"https://www.youtube.com/watch?v=lVCRjWCsPrY&t=54s"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/9b0b1633ad884757971edbd5d04969a5_e=0x0x960x540_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/9b0b1633ad884757971edbd5d04969a5_e=0x0x960x540_bri=115_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/9b0b1633ad884757971edbd5d04969a5_e=0x0x960x540_bri=115_s=860x_.jpg 860w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Dobré ráno ČT 2</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/4ea53e0520594f679f38045186150e63_e=56x0x1165x655_s=660x_.jpg"} url={"https://pardubice.rozhlas.cz/na-detske-domovy-plivou-ti-kteri-tam-nikdy-nebyli-rika-reditel-neziskovky-deti-v-8605558?fbclid=IwAR2zdBq7vi7-8C12T0TbyFQUldH0_Q6UUIgqi45bQWlbXBXukwcyhNNjsYM#player=on"} use={"url"} href={"https://pardubice.rozhlas.cz/na-detske-domovy-plivou-ti-kteri-tam-nikdy-nebyli-rika-reditel-neziskovky-deti-v-8605558?fbclid=IwAR2zdBq7vi7-8C12T0TbyFQUldH0_Q6UUIgqi45bQWlbXBXukwcyhNNjsYM#player=on"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/4ea53e0520594f679f38045186150e63_e=56x0x1165x655_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/4ea53e0520594f679f38045186150e63_e=56x0x1165x655_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/4ea53e0520594f679f38045186150e63_e=56x0x1165x655_s=860x_.jpg 860w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Český rozhlas - Pardubice</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/c15a805b0f684121aa63cc208cad5fe9_e=53x53x766x431_bri=115_s=660x_.jpg"} url={"https://soundcloud.com/ct24/uk-jaka-je-situace-v-detskych-domovech"} use={"url"} href={"https://soundcloud.com/ct24/uk-jaka-je-situace-v-detskych-domovech"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/c15a805b0f684121aa63cc208cad5fe9_e=53x53x766x431_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/c15a805b0f684121aa63cc208cad5fe9_e=53x53x766x431_bri=115_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Události Komentáře ČT 24</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d416cf4a0eea48a39527c5ab0f397079_e=0x32x560x315_s=350x_.jpeg"} url={"https://www.idnes.cz/zpravy/domaci/vychovny-ustav-detsky-domov-sebeposkozovani-deti-v-akci.A220309_230406_domaci_elk?"} use={"url"} href={"https://www.idnes.cz/zpravy/domaci/vychovny-ustav-detsky-domov-sebeposkozovani-deti-v-akci.A220309_230406_domaci_elk?"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/d416cf4a0eea48a39527c5ab0f397079_e=0x32x560x315_s=350x_.jpeg 350w"} target={"_blank"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\">iDNES.cz</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/00ea97a1a773481db188a5b98f8a8516_e=291x406x1692x952_bri=115_s=860x_.jpg"} url={"https://slisty.cz/ladislav-samek-o-aktualni-situaci-v-detskych-domovech/?fbclid=IwAR1nxgxSnANrbmGBwtemsRCz8S3wc-7f-3NMJjZ7ernQYwmjP9ZKv9oGsLs"} use={"url"} href={"https://slisty.cz/ladislav-samek-o-aktualni-situaci-v-detskych-domovech/?fbclid=IwAR1nxgxSnANrbmGBwtemsRCz8S3wc-7f-3NMJjZ7ernQYwmjP9ZKv9oGsLs"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/00ea97a1a773481db188a5b98f8a8516_e=291x406x1692x952_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/00ea97a1a773481db188a5b98f8a8516_e=291x406x1692x952_bri=115_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/00ea97a1a773481db188a5b98f8a8516_e=291x406x1692x952_bri=115_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/00ea97a1a773481db188a5b98f8a8516_e=291x406x1692x952_bri=115_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\">Studentské listy</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/3fc96837680b459c82b83014c50239c4_e=147x61x720x405_s=660x_.jpg"} url={"http://www.romea.cz/cz/zpravodajstvi/domaci/ladislav-samek-nalepky-ustavni-dite-se-spatne-zbavuje?fbclid=IwAR2MlJFaBuW87WEc1onTacBP4WzHlJSzJuMOfWb4433G9_XGdWMoyyjFJBo"} use={"url"} href={"http://www.romea.cz/cz/zpravodajstvi/domaci/ladislav-samek-nalepky-ustavni-dite-se-spatne-zbavuje?fbclid=IwAR2MlJFaBuW87WEc1onTacBP4WzHlJSzJuMOfWb4433G9_XGdWMoyyjFJBo"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/3fc96837680b459c82b83014c50239c4_e=147x61x720x405_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/3fc96837680b459c82b83014c50239c4_e=147x61x720x405_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Romea.cz</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/46da5d7a1ae340ca942112584fab932e_e=0x202x1916x1078_bri=115_s=860x_.jpg"} url={"https://radiozurnal.rozhlas.cz/organizace-deti-v-akci-podporuje-deti-z-detskych-domovu-v-zajmovych-cinnostech-8479299?fbclid=IwAR2w48bGgmigLMs7K7kpgB8VKxNKb2ya4WFKq1jOKKqI89dBdAdyYNCwZ7U"} use={"url"} href={"https://radiozurnal.rozhlas.cz/organizace-deti-v-akci-podporuje-deti-z-detskych-domovu-v-zajmovych-cinnostech-8479299?fbclid=IwAR2w48bGgmigLMs7K7kpgB8VKxNKb2ya4WFKq1jOKKqI89dBdAdyYNCwZ7U"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/46da5d7a1ae340ca942112584fab932e_e=0x202x1916x1078_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/46da5d7a1ae340ca942112584fab932e_e=0x202x1916x1078_bri=115_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/46da5d7a1ae340ca942112584fab932e_e=0x202x1916x1078_bri=115_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/46da5d7a1ae340ca942112584fab932e_e=0x202x1916x1078_bri=115_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Radiožurnál</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/5ec447799e784dac9b3b297eba5aaa73_s=660x_.jpg"} url={"https://zpravy.aktualne.cz/domaci/detske-domovy-jedou-nadoraz-nemaji-personal-ani-pocitace/r~ccb5f5a680cd11ebb9860cc47ab5f122/?fbclid=IwAR3jJiMfi6GifVP0k6gSZ8qqj7ScFKB22W9zpTDGPPgoC92V4ROp4W4Iis0"} use={"url"} href={"https://zpravy.aktualne.cz/domaci/detske-domovy-jedou-nadoraz-nemaji-personal-ani-pocitace/r~ccb5f5a680cd11ebb9860cc47ab5f122/?fbclid=IwAR3jJiMfi6GifVP0k6gSZ8qqj7ScFKB22W9zpTDGPPgoC92V4ROp4W4Iis0"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/5ec447799e784dac9b3b297eba5aaa73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/5ec447799e784dac9b3b297eba5aaa73_s=660x_.jpg 660w"} target={"_blank"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\">Aktuálně.cz</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/8cb6e552c5904878bca67a6430e8fe68_e=0x82x621x349_bri=115_s=350x_.jpg"} url={"https://www.e15.cz/the-student-times/za-oponou-pestounske-pece-kdyz-jsou-novi-rodice-horsi-nez-puvodni-1365623?fbclid=IwAR0aDCkicVdG70AYnJYRN50wUGY5iLpIP0UtpxMOh8wM02HPDXEj-lg33cE#"} use={"url"} href={"https://www.e15.cz/the-student-times/za-oponou-pestounske-pece-kdyz-jsou-novi-rodice-horsi-nez-puvodni-1365623?fbclid=IwAR0aDCkicVdG70AYnJYRN50wUGY5iLpIP0UtpxMOh8wM02HPDXEj-lg33cE#"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/8cb6e552c5904878bca67a6430e8fe68_e=0x82x621x349_bri=115_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\">Student Times,E15</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/00b82556c62740b49f20bcab476f2473_e=702x547x556x313_s=350x_.jpg"} url={"https://vzpravy.cz/zpravy/pardubicko/pardubice/2719/detske-domovy-znaji-svuj-talent?fbclid=IwAR1FzLlesllsCAs_aKSagIpYGRm0MZI2QfvPsVmRg5rm0kQ3VK9SMvbJHIM"} use={"url"} href={"https://vzpravy.cz/zpravy/pardubicko/pardubice/2719/detske-domovy-znaji-svuj-talent?fbclid=IwAR1FzLlesllsCAs_aKSagIpYGRm0MZI2QfvPsVmRg5rm0kQ3VK9SMvbJHIM"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/00b82556c62740b49f20bcab476f2473_e=702x547x556x313_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--20" content={"<span style=\"font-style: italic; font-weight: 700;\">Východočeská televize</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/b7a66fcc4cb34f73b3b3815ea4138dcd_e=28x0x932x524_s=660x_.jpg"} url={"https://www.youtube.com/watch?v=-YWtcGuAZZ8"} use={"url"} href={"https://www.youtube.com/watch?v=-YWtcGuAZZ8"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/b7a66fcc4cb34f73b3b3815ea4138dcd_e=28x0x932x524_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/b7a66fcc4cb34f73b3b3815ea4138dcd_e=28x0x932x524_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/b7a66fcc4cb34f73b3b3815ea4138dcd_e=28x0x932x524_s=860x_.jpg 860w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--style7 title-box--center fs--24" content={"<span style=\"font-weight: bold; font-style: italic;\">ČTK</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}